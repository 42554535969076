import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Statistic,
} from 'antd';
import {PAYMENT_STATUS_DISPLAY} from '../../Domain/Constants';
import * as AppActions from '../../AppActions';
import moment from 'moment';

function AdminPage(props) {
  const [form] = Form.useForm();
  const [statistics, setStatistics] = useState(null);

  const handleFetchStatistic = useCallback(async (data) => {
    try {
      AppActions.setLoading(true);
      const payload = {
        created_from: moment(data.created_from.format('YYYY-MM-DD')).valueOf(),
        created_to: moment(data.created_to.format('YYYY-MM-DD')).valueOf(),
        delivery_date: data.delivery_date
          ? moment(data.delivery_date.format('YYYY-MM-DD')).valueOf()
          : undefined,
        payment_status: data.payment_status ? data.payment_status : undefined,
      };
      const resp = await AppActions.getOrderStatistics(payload);
      setStatistics(resp);
    } catch (e) {
      console.log(e);
      setStatistics(null);
    } finally {
      AppActions.setLoading(false);
    }
  }, []);

  useEffect(() => {
    const _fetch = async () => {
      try {
        if (!statistics) {
          await handleFetchStatistic({
            created_from: moment(new Date()).add(-30, 'day'),
            created_to: moment(new Date()),
            delivery_date: undefined,
            payment_status: undefined,
          });
        }
      } catch (e) {
        console.log(e);
        setStatistics(null);
      }
    };
    _fetch();
  }, [handleFetchStatistic, statistics]);

  return (
    <Wrapper>
      <h1>首頁</h1>
      <Form
        initialValues={{
          created_from: moment(new Date()).add(-30, 'day'),
          created_to: moment(new Date()),
          delivery_date: undefined,
          payment_status: undefined,
        }}
        style={{maxWidth: 350}}
        form={form}
        onFinish={handleFetchStatistic}>
        <Form.Item
          style={{display: 'flex', flexDirection: 'column'}}
          labelAlign="left"
          name="created_from"
          rules={[
            {
              required: true,
              message: '必填',
            },
          ]}
          label="起日">
          <DatePicker placeholder="請選擇起日" />
        </Form.Item>
        <Form.Item
          style={{display: 'flex', flexDirection: 'column'}}
          labelAlign="left"
          name="created_to"
          rules={[
            {
              required: true,
              message: '必填',
            },
          ]}
          label="迄日">
          <DatePicker placeholder="請選擇迄日" />
        </Form.Item>
        <Form.Item
          style={{display: 'flex', flexDirection: 'column'}}
          labelAlign="left"
          name="delivery_date"
          label="出貨日">
          <DatePicker placeholder="請選擇出貨日" />
        </Form.Item>
        <Form.Item
          style={{display: 'flex', flexDirection: 'column'}}
          labelAlign="left"
          name="payment_status"
          label="付款狀態">
          <Select
            allowClear
            placeholder="請選擇付款狀態"
            options={[
              ...Object.entries(PAYMENT_STATUS_DISPLAY).map((pair) => ({
                label: pair[1],
                value: pair[0],
              })),
            ]}
          />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit">查詢</Button>
        </Form.Item>
      </Form>
      {statistics && (
        <Row gutter={16}>
          <Col span={12}>
            <Card>
              <Statistic
                title="訂單總金額"
                value={statistics.total}
                prefix={null}
                suffix="元"
              />
            </Card>
          </Col>
          <Col span={12}>
            <Card>
              <Statistic
                title="訂單數量"
                value={statistics.count}
                prefix={null}
                suffix="張"
              />
            </Card>
          </Col>
        </Row>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;

  & > h1 {
    font-size: 32px;
  }
`;

export default AdminPage;
